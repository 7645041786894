//A styling wrapper on the Monzo UI Button component

import cx from 'classnames'
import React from 'react'

import { ButtonProps, Button as MonzoUIButton } from '@mondough/monzo-ui'

import styles from './Button.module.scss'

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const theme = props.theme || 'primary'
  const size = props.size || 'regular'
  return (
    <MonzoUIButton
      {...props}
      ref={ref}
      className={cx(
        props.className,
        styles.button,
        styles[theme],
        styles[size],
      )}
    />
  )
})

export default Button
