import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import {
  COOKIE_BANNER_DISMISSED,
  getCookie,
  setCookieBannerDismissed,
  setCookieConsent,
} from '../../utils'
import { BottomSheet, Button } from '../'
import styles from './CookieBanner.module.scss'

const CookieBanner = () => {
  // Don't flash the banner up if the user has previously accepted cookies
  const [hideBanner, setHideBanner] = React.useState(true)
  const router = useRouter()
  const formatMessage = useFormatMessage()

  React.useEffect(() => {
    if (router.pathname === '/cookies') {
      setHideBanner(true)
      return
    }
    setHideBanner(getCookie(COOKIE_BANNER_DISMISSED) === 'true')
  }, [hideBanner, router.pathname])

  if (hideBanner) return null

  const setCookies = async (value: 'true' | 'false') => {
    await setCookieConsent(value)
    setCookieBannerDismissed()
    setHideBanner(true)
  }

  return (
    <BottomSheet contentClassName={styles.content}>
      <div className={styles.text}>
        <Title is="h2">{formatMessage('cookies.banner.title')}</Title>
        <Text>
          {formatMessage('cookies.banner.text')}
          <Link href="/cookies">
            <a
              {...buildActionAnalyticsAttributes('cookie-notice')}
              onClick={() => setHideBanner(true)}
            >
              {formatMessage('cookies.banner.link.text')}
            </a>
          </Link>
          .
        </Text>
      </div>

      <div className={styles['button-group']}>
        <Button
          theme="primary"
          onClick={() => setCookies('true')}
          data-testid="accept-cookies"
          fullWidth
          {...buildActionAnalyticsAttributes('accept-all')}
        >
          {formatMessage('cookies.banner.button.accept')}
        </Button>
        <Button
          theme="primary"
          onClick={() => setCookies('false')}
          data-testid="decline-cookies"
          fullWidth
          {...buildActionAnalyticsAttributes('decline-all')}
        >
          {formatMessage('cookies.banner.button.decline')}
        </Button>
        <Button
          theme="secondary"
          data-testid="cookie-prefs"
          onClick={async () => {
            setHideBanner(true)
            await router.push('/cookies')
          }}
          fullWidth
          className={styles['preferences-button']}
          {...buildActionAnalyticsAttributes('preferences')}
        >
          {formatMessage('cookies.banner.button.settings')}
        </Button>
      </div>
    </BottomSheet>
  )
}

export default CookieBanner
