import React, { useEffect, useState } from 'react'

import { useFormatMessage } from '../hooks'
import { NUMBER_OF_CUSTOMERS_MILLIONS } from '../utils'
import { TabCreatorReferral, fetchMaxInterestRate } from '../utils'

export const ReferralContext = React.createContext({
  referralData: null as TabCreatorReferral | null,
  setReferral: (_referral: TabCreatorReferral | null) => {
    return
  },
  defaultReferralText: '' as string,
})

export const ReferralProvider = ({ children }: { children: JSX.Element }) => {
  const [referralData, setReferralData] = useState<TabCreatorReferral | null>(
    null,
  )
  const formatMessage = useFormatMessage()
  const defaultReferralCopy = formatMessage('referral.default.description', {
    numberOfMillionCustomers: NUMBER_OF_CUSTOMERS_MILLIONS,
    savingsSentence: '',
  })
  const [defaultReferralText, setDefaultReferralText] =
    useState<string>(defaultReferralCopy)

  useEffect(() => {
    const fetchMaxSavingsRate = async () => {
      try {
        const data = await fetchMaxInterestRate()
        if (data) {
          const savingsSentence = formatMessage('referral.default.savings', {
            maxSavingsRate: data,
          })
          const newCopy = formatMessage('referral.default.description', {
            numberOfMillionCustomers: NUMBER_OF_CUSTOMERS_MILLIONS,
            savingsSentence,
          })
          setDefaultReferralText(newCopy)
        }
      } catch (e) {}
    }
    void fetchMaxSavingsRate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReferralContext.Provider
      value={{
        referralData,
        setReferral: setReferralData,
        defaultReferralText,
      }}
    >
      {children}
    </ReferralContext.Provider>
  )
}
