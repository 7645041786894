import Head from 'next/head'
import Link from 'next/link'
import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import { TabContext } from '../../providers/TabProvider'
import styles from './TabLayout.module.scss'
import { TabLayoutProps } from './TabLayout.types'
import { Button, Loading } from '..'

const TabLayout: React.FC<TabLayoutProps> = ({ children }: TabLayoutProps) => {
  const { tab, pageTitle, breadcrumb } = React.useContext(TabContext)
  const formatMessage = useFormatMessage()

  if (!tab) {
    return <Loading />
  }

  return (
    <>
      <Head>
        <title>
          {formatMessage('default.page.title')} | {tab.name}
        </title>
      </Head>
      <section className={styles.tab}>
        {breadcrumb.title && (
          <Link href={breadcrumb.href}>
            <Button
              size="small"
              theme="invisible"
              icon="chevron-left"
              iconSize={14}
              className={styles['tab-breadcrumb']}
              {...buildActionAnalyticsAttributes('details-breadcrumb', {
                breadcrumb: breadcrumb.title,
              })}
            >
              {breadcrumb.title}
            </Button>
          </Link>
        )}
        {pageTitle && (
          <Title is="h1" size="medium" className={styles['tab-title']}>
            {pageTitle}
          </Title>
        )}
        <section className={styles['tab-content']}>{children}</section>
      </section>
    </>
  )
}

export default TabLayout
