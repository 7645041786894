import * as React from 'react'
import { useIntl } from 'react-intl'

import { Button, Text } from '@mondough/monzo-ui'

import AppStoreButton from '../AppStoreButton/AppStoreButton'
import styles from './Footer.module.scss'
import { FooterProps } from './Footer.props'

export default function Footer({ links }: FooterProps) {
  const intl = useIntl()
  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <section className={styles.links}>
          {links.map((link) => (
            <Button
              key={link.id}
              href={link.href}
              theme="invisible"
              className={styles.link}
            >
              {link.children}
            </Button>
          ))}
        </section>
        <Text color="white" className={styles.text}>
          {intl.formatMessage({ id: 'footer.legal' })}
        </Text>
        <section className={styles.download}>
          <AppStoreButton href="https://monzo.com/download" store="app-store" />
          <AppStoreButton
            href="https://monzo.com/download"
            store="google-play"
          />
        </section>
      </div>
    </footer>
  )
}
