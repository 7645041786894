import { enGBKeys } from '../copy/en-GB'
import apiFetch from './apiFetch'
import { captureSharedTabsException } from './errorHandling'

export type FeedbackFormKey =
  | 'easeOfUseSelect'
  | 'magicWandText'
  | 'npsSelect'
  | 'whyNotMonzoText'

export const feedbackFormQuestionKeys: FeedbackFormKey[] = [
  'easeOfUseSelect',
  'magicWandText',
  'npsSelect',
  'whyNotMonzoText',
]

export type FeedbackFormValues = {
  easeOfUseSelect: string
  magicWandText: string
  npsSelect: string
  whyNotMonzoText: string
}

export const feedbackQuestionTypes: Record<FeedbackFormKey, 'radio' | 'text'> =
  {
    easeOfUseSelect: 'radio',
    magicWandText: 'text',
    npsSelect: 'radio',
    whyNotMonzoText: 'text',
  }

export const feedbackQuestionOptions: Partial<
  Record<FeedbackFormKey, string[]>
> = {
  easeOfUseSelect: Array.from(
    { length: 5 },
    (_, i) => `feedback.select.easeOfUseSelect.option${i + 1}`,
  ),
  npsSelect: Array.from(
    { length: 10 },
    (_, i) => `feedback.select.npsSelect.option${i + 1}`,
  ),
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const sendTabsFeedback = async (
  feedback: FeedbackFormValues,
  formatMessage: (
    id: enGBKeys,
    values?: Record<string, string | number>,
  ) => string,
) => {
  const answers = Object.entries(feedback).map(([key, value]) => ({
    question_id: camelToSnakeCase(key),
    question_text: formatMessage(`feedback.question.${key}` as enGBKeys),
    answer: value,
  }))

  try {
    await apiFetch('tabs/feedback', {
      method: 'POST',
      body: JSON.stringify({
        answers,
      }),
    })
  } catch (error) {
    captureSharedTabsException(error as Error, {
      feedback,
    })
    throw error
  }
}
