import Head from 'next/head'
import React, { useContext } from 'react'

import { SkipLink } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import { ReferralContext } from '../../providers'
import lifestyleReferral from '../../public/images/bill-split.png'
import lifestyleGeneric from '../../public/images/monzo-campaign.webp'
import styles from './Layout.module.scss'
import {
  CookieBanner,
  Footer,
  Header,
  MultipleBlockBanner,
  SingleBlockBanner,
} from '..'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { referralData, defaultReferralText } = useContext(ReferralContext)
  const formatMessage = useFormatMessage()
  const displayReferral = referralData !== null

  const headTitle = formatMessage('default.page.title') ?? 'Monzo | Shared Tabs'
  const description =
    formatMessage('default.page.description') ??
    'Split expenses, send reminders, and settle up with anyone.'
  const url = formatMessage('default.page.url') ?? 'https://tabs.monzo.me'

  return (
    <div className={styles.app}>
      <Head>
        <title>{headTitle}</title>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, maximum-scale=5"
        />
        <meta name="robots" content="noindex" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={headTitle} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/images/monzo-campaign.webp" />
        <meta property="og:locale" content="en_GB" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@monzo" />
        <meta name="twitter:title" content={headTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="/images/monzo-campaign.webp" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="description" content={description} />
        <meta name="theme-color" content="#112231" />
      </Head>
      <SkipLink target="main" />
      <CookieBanner />
      <Header />
      <main className={styles.main} id="main">
        <div className={styles['desktop-row-container']}>
          <section className={styles['left-side-content']}>
            <div className={styles.content}>{children}</div>
          </section>
          <aside className={styles['right-side-content']}>
            <SingleBlockBanner
              title={
                displayReferral
                  ? referralData?.title
                  : formatMessage('referral.default.title')
              }
              text={
                displayReferral
                  ? referralData?.description
                  : defaultReferralText
              }
              imageSrc={displayReferral ? lifestyleReferral : lifestyleGeneric}
              buttonText={
                displayReferral
                  ? formatMessage('referral.claim.button')
                  : formatMessage('referral.default.button')
              }
              buttonHref={
                displayReferral
                  ? referralData?.link
                  : 'https://monzo.com/sign-up/?utm_source=tabsmonzome&utm_medium=website'
              }
            />
          </aside>
        </div>
        <MultipleBlockBanner />
      </main>
      <Footer
        links={[
          {
            id: 'privacy-notice',
            href: 'https://monzo.com/legal/privacy-notice/',
            children: formatMessage('footer.privacy-notice'),
            analyticsName: 'footer-privacy-notice',
          },
          {
            id: 'cookies',
            href: '/cookies',
            children: formatMessage('footer.cookie-notice'),
            analyticsName: 'footer-cookie-notice',
          },
          {
            id: 't-and-c',
            href: 'https://monzo.com/legal/terms-and-conditions/',
            children: formatMessage('footer.terms-and-conditions'),
            analyticsName: 'terms-and-conditions',
          },
        ]}
      />
    </div>
  )
}

export default Layout
