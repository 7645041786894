import cx from 'classnames'
import Head from 'next/head'
import Link from 'next/link'
import React, { useContext } from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import { AuthContext } from '../../providers'
import { Button } from '../'
import styles from './PlainLayout.module.scss'
import { PlainLayoutProps } from './PlainLayout.types'

const PlainLayout: React.FC<PlainLayoutProps> = ({
  pageTitle,
  displayTitle = true,
  displayBreadcrumb = true,
  displayCardLayout = true,
  children,
  className,
}: PlainLayoutProps) => {
  const { isSessionUpgraded } = useContext(AuthContext)
  const formatMessage = useFormatMessage()
  const headTitle = formatMessage('default.page.title')
  return (
    <>
      <Head>
        <title>{pageTitle ? `${headTitle} | ${pageTitle}` : headTitle}</title>
      </Head>
      <section className={cx(styles.container, className)}>
        {displayBreadcrumb && isSessionUpgraded && (
          <Link href={'/'}>
            <Button
              size="small"
              theme="invisible"
              icon="chevron-left"
              className={styles['breadcrumb']}
              {...buildActionAnalyticsAttributes('details-breadcrumb', {
                breadcrumb: formatMessage('breadcrumb.tabs'),
              })}
            >
              {formatMessage('breadcrumb.tabs')}
            </Button>
          </Link>
        )}
        {pageTitle && displayTitle && (
          <Title is={'h1'} size="medium" className={styles.heading}>
            {pageTitle}
          </Title>
        )}
        {displayCardLayout ? (
          <article className={styles.card}>{children}</article>
        ) : (
          children
        )}
      </section>
    </>
  )
}

export default PlainLayout
