import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'

import { usePageTitle } from '../../../hooks'
import statusDone from '../../../public/images//illustrations/status-done.svg'
import { getCDNSrc } from '../../../utils'
import ActionCard from '../ActionCard'
import { SuccessCardProps } from './SuccessCard.types'

const SuccessCard: React.FC<SuccessCardProps> = ({
  id,
  title,
  text,
  buttonText,
  buttonAction,
  buttonAnalyticsName,
  secondaryButtonText,
  secondaryButtonAction,
  secondaryButtonAnalyticsName,
}: SuccessCardProps) => {
  usePageTitle('')
  return (
    <ActionCard
      id={id}
      assetNode={<img src={getCDNSrc(statusDone)} alt="" width={80} />}
      title={title}
      text={text}
      primaryButton={{
        text: buttonText,
        onClick: buttonAction,
        ...buildActionAnalyticsAttributes(buttonAnalyticsName || 'done'),
      }}
      secondaryButton={
        secondaryButtonText && secondaryButtonAction
          ? {
              text: secondaryButtonText,
              onClick: secondaryButtonAction,
              ...buildActionAnalyticsAttributes(
                secondaryButtonAnalyticsName || 'done',
              ),
            }
          : undefined
      }
    />
  )
}

export default SuccessCard
