import cx from 'classnames'
import React from 'react'

import { Money, PlainCard, Text, Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import monzoLogo from '../../public/images/monzo-logo.svg'
import {
  TabParticipant,
  getAuthValue,
  getCDNSrc,
  getUserProfileSrc,
  isMonzoUser,
} from '../../utils'
import { AvatarWithBadge } from '../'
import styles from './People.module.scss'
import { PeopleProps, Person } from './People.types'

const mapParticipantToPerson = (participant: TabParticipant): Person => {
  return {
    id: participant.user_id,
    firstName:
      participant.user_id === getAuthValue('userId')
        ? 'You'
        : participant.first_name,
    fullName: participant.name,
    owedValue: participant.settle_amount,
    avatarSrc: getUserProfileSrc(participant.user_id),
    isMonzoUser: isMonzoUser(participant),
  }
}

const People: React.FC<PeopleProps> = ({ people = [] }: PeopleProps) => {
  const formatMessage = useFormatMessage()
  return (
    <PlainCard className={styles.wrapper} as="section">
      <Title
        is="h2"
        size="extra-small"
        className={styles.title}
        marginLeft="small"
      >
        {formatMessage(
          `details.people.title.${people.length > 1 ? 'plural' : 'singular'}`,
          {
            count: people.length,
          },
        )}
      </Title>
      <ul className={cx(styles.people, 'custom-scrollbar')}>
        {people.map(mapParticipantToPerson).map((person, index) => {
          return (
            <li key={index}>
              <article className={styles.person}>
                <AvatarWithBadge
                  src={person.avatarSrc}
                  placeholderName={person.fullName}
                  size="large"
                  className={styles.avatar}
                  badge={
                    person.isMonzoUser
                      ? {
                          type: 'profile',
                          src: getCDNSrc(monzoLogo),
                          shape: 'square',
                        }
                      : undefined
                  }
                />
                <div
                  className={person.firstName.length > 8 ? styles.marquee : ''}
                >
                  <Text size="small" marginTop="extra-small" bold>
                    {person.firstName}
                  </Text>
                </div>
                <Money
                  amount={person.owedValue}
                  color={
                    person.owedValue < 0
                      ? 'red2'
                      : person.owedValue === 0
                      ? 'secondary'
                      : 'green1'
                  }
                  bold
                  symbolClassName={styles['money-symbol']}
                  majorClassName={styles['money-major']}
                  minorClassName={styles['money-minor']}
                  className={styles['money-amount']}
                />
              </article>
            </li>
          )
        })}
      </ul>
    </PlainCard>
  )
}

export default People
