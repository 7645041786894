import React, { useEffect, useState } from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../../hooks'
import {
  getNotificationPreferences,
  setNotificationPreferences,
} from '../../../utils'
import { BottomSheet, Button, SwitchWithLabel } from '../..'
import styles from './NotificationConsentBanner.module.scss'

const NotificationConsentBanner: React.FC = () => {
  const [activityConsent, setActivityConsent] = useState(true)
  const [showBanner, setShowBanner] = useState(false)
  const formatMessage = useFormatMessage()

  const [notificationConsentError, setNotificationConsentError] =
    React.useState(false)

  useEffect(() => {
    const fetchNotificationConsent = async () => {
      try {
        const preferences = await getNotificationPreferences()
        setActivityConsent(preferences.notifications_enabled)
        setShowBanner(preferences.using_default)
      } catch {
        // Do nothing
      }
    }
    void fetchNotificationConsent()
  }, [])

  if (!showBanner) return null

  return (
    <BottomSheet
      id="notification-consent-banner"
      contentClassName={styles.content}
      overlayZIndex={102}
      containerZIndex={103}
    >
      <div className={styles.text}>
        <Title is="h2">{formatMessage('notification.banner.title')}</Title>
        <Text color="secondary">
          {formatMessage('notification.banner.text')}
        </Text>
      </div>
      <SwitchWithLabel
        id="tab-notifications"
        label={formatMessage('notification.banner.switch.activity.label')}
        subLabel={formatMessage(
          'notification.banner.switch.activity.sublabel',
          {
            activityConsent: activityConsent
              ? formatMessage(
                  'notification.banner.switch.activity.sublabel.off',
                )
              : formatMessage(
                  'notification.banner.switch.activity.sublabel.on',
                ),
          },
        )}
        checked={activityConsent}
        onChange={() => setActivityConsent((value) => !value)}
      />

      <Button
        theme="primary"
        fullWidth
        onClick={async () => {
          try {
            await setNotificationPreferences(activityConsent)
            setShowBanner(false)
          } catch {
            setNotificationConsentError(true)
          }
        }}
        marginTop="medium"
        className={styles.button}
        {...buildActionAnalyticsAttributes('confirm', {
          enabled: activityConsent,
        })}
      >
        {formatMessage('notification.banner.button')}
      </Button>
      {notificationConsentError && (
        <Text size="small" color="destructive">
          {formatMessage('notification.banner.error.text')}
        </Text>
      )}
    </BottomSheet>
  )
}

export default NotificationConsentBanner
