import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { captureSharedTabsException } from '../../utils'
import styles from './ErrorBoundary.module.scss'

type ErrorState = { error: Error | null | undefined }

type Props = {
  children: React.ReactNode
}

export default class ErrorBoundary extends React.Component<Props, ErrorState> {
  override state = {
    error: null,
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  override componentDidCatch(
    error: Error,
    info: React.ErrorInfo | Record<string, unknown>,
  ) {
    captureSharedTabsException(error, info)
  }

  override render() {
    if (this.state.error != null) {
      return (
        <div className={styles.error}>
          <h2>
            <FormattedMessage id="error.title" />
          </h2>
          <FormattedMessage id="error.default.text" />
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}
