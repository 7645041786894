export const enGB = {
  'default.page.title': 'Monzo | Shared Tabs',
  'default.page.description':
    'Split expenses, send reminders, and settle up with anyone.',
  'default.page.url': 'https://tabs.monzo.me',
  'breadcrumb.tabs': 'Your Shared Tabs',
  'home.page.title': 'Your Shared Tabs',
  'home.page.no-active-tabs':
    "It doesn't look like you're a part of any active tabs",
  'home.page.settled-tabs': 'Settled tabs',
  'home.page.logout.title': 'Log out',
  'home.page.logout.subtitle': 'You can log back in at any time',
  'home.page.archived.subtitle.with-date': 'Archived on {date}',
  'home.page.archived.subtitle.without-date': 'Archived',
  'home.page.active.subtitle.owed': 'You are owed {amount}',
  'home.page.active.subtitle.owes': 'You owe {amount}',
  'cookies.page.title': 'Cookies on Shared Tabs',
  'cookies.card.intro':
    "Cookies help us give you a better Shared Tabs experience. \n Think of cookies as stickers - every time you visit a site, a sticker is put on you to tally the number of times you've visited, how long you've spent there, and what you've done. \n That lets the site show things relevant to you, based on information you've entered and stuff you've looked at. The length of time stored depends on the cookie, but this is generally between a few minutes and up to two years.",
  'cookies.card.settings.title': 'Cookie settings',
  'cookies.card.settings.default.title':
    "Cookies to store your settings (these can't be turned off)",
  'cookies.card.settings.default.description':
    "We use cookies to recognise you when you come back to our site. When we spot it's you, we know whether or not to show you things you've already seen (like our cookie banner).",
  'cookies.card.settings.analytics.title':
    'Cookies to give you a better experience (these can be turned off)',
  'cookies.card.settings.analytics.description':
    'We use analytics cookies to see where you spend your time, and how you interact with our site. This helps us improve the site, so it works better for you.',
  'cookies.card.settings.form.legend':
    'Choose to switch optional cookies on or off',
  'cookies.card.settings.form.accept': 'Optional cookies on',
  'cookies.card.settings.form.reject': 'Optional cookies off',
  'cookies.card.settings.form.cta': 'Save preferences',
  'cookies.card.settings.form.success': 'Your preferences have been updated.',
  'cookies.card.settings.form.error': 'Something went wrong. Please try again.',
  'cookies.card.other.title': 'Other ways to turn off cookies',
  'cookies.card.other.description':
    "You can also turn all cookies off in your browser or phone settings. \n If you want to do this, try looking in your internet browser's help menu, or searching for 'how to block cookies'. Your browser also lets you delete any cookies stored on your device, whenever you wish.",
  'cookies.card.questions.title': 'Questions?',
  'cookies.card.questions.description':
    'We take privacy very seriously and are always open to your feedback. If you have any more questions about our cookie notice or how we use cookies please email ',
  'cookies.banner.title': 'Can we use optional cookies?',
  'cookies.banner.text':
    "We're not talking about the crunchy, tasty kind. These cookies help us give you a better experience. We won't turn them on unless you accept. Want to know more or adjust your preferences? Here's our ",
  'cookies.banner.link.text': 'cookie notice',
  'cookies.banner.button.accept': 'Accept all',
  'cookies.banner.button.decline': 'Decline all',
  'cookies.banner.button.settings': 'Cookie settings',
  'faq.page.title': 'Shared Tabs: Frequently Asked Questions',
  'faq.what-are-shared-tabs.question': 'What are Shared Tabs?',
  'faq.what-are-shared-tabs.answer':
    "Shared Tabs help you keep track of joint expenses like household expenses and holidays. Think of it like a group wallet.\n You can now be added to a Shared Tab even if you're not a Monzo customer. We've only just made this change, so we're open to any feedback you have.",
  'faq.how-do-i-join-a-shared-tab.question': 'How do I join a Shared Tab?',
  'faq.how-do-i-join-a-shared-tab.answer':
    "You need to be invited by a Monzo customer. Once you're invited, there are two ways to join – by scanning a QR code on their app, or via email/message.\n Whichever way you choose, we'll send you a magic link that'll give you access to the Tab. \nYou can use Shared Tabs on your phone, desktop or iPad.",
  'faq.invite-link-doesnt-work.question':
    "The invite link doesn't work, what do I do?",
  'faq.invite-link-doesnt-work.answer':
    "For security reasons, invite links are only valid for a certain amount of time. If the link isn't working, just ask your Monzo friend to send you a new one.",
  'faq.trouble-login.question':
    'I’m having trouble joining a tab or logging in, what do I do?',
  'faq.trouble-login.answer':
    "If you’ve been sent a valid invite link but are still having trouble joining a tab, make sure you're using the same browser (e.g Chrome, Safari, Firefox) for opening both the invite link and the magic link. \n Some social media apps such as Instagram and Snapchat have their own internal (webview) browsers which aren’t always compatible with our invite links. If you're using one of these apps to open your invite, try copying the link and pasting it into a different internet browser.\n Similarly, your mail app might be opening the magic link in its own internal browser too. Make sure to open the magic link on the same browser you requested it from.",
  'faq.get-back-on-tab.question':
    "I've just closed the Shared Tabs page, how do I get back on it?",
  'faq.get-back-on-tab.answer':
    "If you're using the same device, either go to your tab link (we recommend bookmarking it) or visit tabs.monzo.me. \n If you're logged out or using a different device, you'll be asked to enter your email address so we can send you a new magic link. Click on it to access your Tab again.",
  'faq.pay-and-be-paid.question': 'Can I pay and be paid via the Tab itself?',
  'faq.pay-and-be-paid.answer':
    "At the moment, no. You'll need to pay in cash or by bank transfer through your own banking app. \n Similarly, if you add an expense, then the people who owe you money will need to pay you in cash or by bank transfer.\n Once you've made a payment, you mark it as paid within the Tab. Your Monzo friend will be able to see this, too.",
  'faq.add-expense.question': 'How do I add an expense?',
  'faq.add-expense.answer':
    "When you're in the Tab, you should see an 'Add custom expense' option. \n Tap on this and type in the amount, then give it a name such as 'Grocery shop' so your friends know what it's for. Once you hit 'Next', you’ll be able to specify how much each person owes.",
  'faq.trouble-payment.question':
    "How do I get help if I'm having trouble with a payment or Tab?",
  'faq.trouble-payment.answer':
    "If you're having trouble making a payment, you'll likely need to speak to your own bank, as this may be an issue on their end. \n If you have any other concerns, please speak to the owner of the Shared Tab. They can reach out to us, and we'll do our best to help.",
  'faq.locked-features.question':
    'Why is there a 🔒 beside some of the features?',
  'faq.locked-features.answer':
    "These are features that only Monzo customers can use. If you'd like to use these, you can apply for a free account – just tap on the 'sign up' button on the top right of your screen.",
  'faq.transfer-shared-tabs.question':
    'If I sign up for a Monzo account, do my Shared Tabs get transferred across?',
  'faq.transfer-shared-tabs.answer':
    "Yep! If you sign up with the email address you used when joining the Shared Tab, it'll appear in your new Monzo account.",
  'faq.share-feedback.question': 'How can I share feedback?',
  'faq.share-feedback.answer':
    "Once you join a tab, you'll see a button near the bottom of your screen that says 'Give feedback'. Tap on this to tell us what you think.\n This feedback form isn't actively monitored by our customer support team, so you'll need to speak to the Shared Tab owner if you need any help.",
  'header.logo.alt': 'Monzo',
  'header.primary-button': 'Get a free bank account',
  'header.logout': 'Log out',
  'footer.privacy-notice': 'Privacy notice',
  'footer.cookie-notice': 'Cookie notice',
  'footer.terms-and-conditions': 'Terms & conditions',
  'footer.legal':
    'Monzo Bank Limited is a company registered in England and Wales (No.09446231). Monzo Bank Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Our financial Services Register number is 730427. Our address is Broadwalk House, 5 Appold St, London EC2A 2AG.',
  'referral.default.title': 'Make your money more Monzo',
  'referral.default.description':
    'More than {numberOfMillionCustomers} million customers use our hot coral cards to spend, save and manage their money. {savingsSentence} \nUK residents only. Ts&Cs apply.',
  'referral.default.button': 'Get a free bank account',
  'referral.default.savings':
    'Earn up to {maxSavingsRate}% AER on a 6-month fixed term savings account.',
  'referral.claim.button': 'Claim referral',
  'marketing.banner-discover.title': 'Make your money more Monzo',
  'marketing.banner-discover.button': 'Find out more',
  'marketing.banner-faq.title': 'Frequently asked questions',
  'marketing.banner-faq.text':
    "New to Shared Tabs and got a question? Head over to our FAQs, where we've answered the most popular ones.",
  'marketing.banner-faq.button': 'See FAQs',
  'marketing.download-block.title': 'Open a free bank account',
  'marketing.trust-grid.bba.alt':
    'British Bank Awards Best Banking App Winner 2023',
  'marketing.trust-grid.cass.alt': 'Current Account Switch Guarantee',
  'marketing.trust-grid.fscs.alt':
    'Financial Services Compensation Scheme Protected',
  'delete-item.title': 'Do you want to remove {itemName} from {tabName}?',
  'delete-item.text': 'You can always add it again later.',
  'delete-item.button.remove': 'Remove {itemName}',
  'delete-item.button.keep': 'Keep {itemName}',
  'delete-item.success.title': 'You removed {itemName}',
  'delete-item.success.text': '',
  'delete-item.success.button': 'Back to {tabName}',
  'delete-item.error.title': 'Something went wrong',
  'delete-item.error.text':
    'There was an error deleting this item. Please try again.',
  'delete-item.error.button': 'Try again',
  'invite.error.expired.title': 'The tab link has expired',
  'invite.error.expired.text':
    "We refresh links periodically to keep your tab secure. You'll need to ask someone in the group for a new one.",
  'invite.error.default.title': "We don't recognize this link",
  'invite.error.default.text': 'It could have been mistyped or miscopied.',
  'invite.title': '{tabCreatorName} invited you to join {tabName}',
  'invite.text': 'Split expenses, send reminders, and settle up with anyone.',
  'invite.button': "Let's go",
  'login.error.mismatch.title': 'Try a different browser',
  'login.error.mismatch.text':
    "We can't log you in because your mail app might be opening the magic link in a different browser. \n To fix it, copy and open this link on the same browser you requested it from.",
  'login.error.mismatch.faq':
    "If you're still having trouble, our FAQs might help.",
  'login.error.mismatch.button': 'Copy link to clipboard',
  'login.error.default.title': 'Woops!',
  'login.error.default.text':
    'There was a problem with this link, please try again.',
  'login.error.default.button': 'Request a new magic link',
  'login.error.used.text': 'This magic link has already been used.',
  'login.error.expired.text': 'This magic link has expired.',
  'login.confirmation.title': "Confirm it's you",
  'login.confirmation.text.first-login':
    'This is your first login{withTabName}.',
  'login.confirmation.text.with-tab': " and you're about to join {tabName}",
  'login.confirmation.text.username': ' ({userName})',
  'login.confirmation.text.returning-user':
    "You're logging in as {userEmail}{userName}{withTabName}.",
  'login.confirmation.button.confirm': "Yes, it's me",
  'login.confirmation.button.logout': 'No, login with another email',
  'notification.banner.title': 'Tab notification settings',
  'notification.banner.text':
    "We won't market to you or share your email with anyone. You can always change your settings later.",
  'notification.banner.switch.activity.label': 'Tab activity',
  'notification.banner.switch.activity.sublabel':
    'Tap to turn {activityConsent}',
  'notification.banner.switch.activity.sublabel.on': 'on',
  'notification.banner.switch.activity.sublabel.off': 'off',
  'notification.banner.button': 'Got it',
  'notification.banner.error.text': 'Something went wrong. Please try again.',
  'activity.title': 'Activity',
  'activity.show-more': 'Show more',
  'app-store-button.ios.alt': 'Download on the Apple App Store',
  'app-store-button.android.alt': 'Get it on Google Play',
  'error.title': 'Woops!',
  'error.default.text':
    'Something went wrong on our end. Please try again later.',
  'error.404.text': "Sorry, the page you are looking for doesn't exist.",
  'settle-up.page.breakdown.title': 'Settle up',
  'settle-up.page.breakdown.description':
    "Once you've paid people in {tabName}, you can settle up here.",
  'settle-up.page.breakdown.info':
    'We settle tabs with fewer transfers between people.',
  'settle-up.page.mark-as-paid.title': 'Choose amount',
  'settle-up.page.mark-as-paid.item.subtitle': 'You still owe',
  'settle-up.page.mark-as-paid.quantity.title': 'Mark amount paid',
  'settle-up.page.mark-as-paid.quantity.label': 'Amount',
  'settle-up.page.mark-as-paid.description':
    'Since you don’t have a Monzo account, you’re only marking the amount as paid',
  'settle-up.page.mark-as-paid.button': 'Mark {amount} as paid',
  'settle-up.success.title': 'You marked {amount} as paid to {fullName}',
  'settle-up.success.text':
    'Sign up for a free account to pay directly in app.',
  'settle-up.success.primary-button': 'Back to {tabName}',
  'settle-up.success.secondary-button': 'Continue settling up',
  'settle-up.error.title': 'Something went wrong',
  'settle-up.error.text':
    'There was an error marking this as paid. Please try again.',
  'settle-up.error.button': 'Try again',
  'add-bill.page.title': 'Add custom expense',
  'add-bill.page.initial.quantity.label': 'Expense amount',
  'add-bill.page.initial.quantity.placeholder': '0.00',
  'add-bill.page.initial.description.label': 'Expense description',
  'add-bill.page.initial.description.placeholder': 'What is this expense?',
  'add-bill.page.initial.upsell.title': 'Add transactions directly',
  'add-bill.page.initial.upsell.subtitle':
    'Get a full account to use this feature',
  'add-bill.page.initial.button': 'Next',
  'add-bill.page.split.item.subtitle': 'Today',
  'add-bill.page.split.banner.dynamicText':
    '{firstItems} and {lastItem}{others}',
  'add-bill.page.split.banner.dynamicText.others.singular': ' other',
  'add-bill.page.split.banner.dynamicText.others.plural': ' others',
  'add-bill.page.split.banner.text':
    "We'll let {dynamicText} know you've added this bill.",
  'add-bill.page.split.primary-button': 'Add to {tabName}',
  'add-bill.page.split.secondary-button': 'Go back',
  'add-bill.success.title': 'You added an expense',
  'add-bill.success.text': 'Get a free account to add purchases directly.',
  'add-bill.success.primary-button': 'Back to {tabName}',
  'add-bill.error.title': 'Something went wrong',
  'add-bill.error.text':
    'There was an error adding this expense. Please try again.',
  'add-bill.error.button': 'Try again',
  'feedback.title': 'Give feedback',
  'feedback.text-area.description':
    "Please give as much detail as you can to help us understand your answer. Don't include any personal data, like card details or your address.",
  'feedback.text-area.placeholder': 'Tell us what you think',
  'feedback.next': 'Next',
  'feedback.back': 'Back',
  'feedback.submit': 'Submit',
  'feedback.error.title': 'Something went wrong',
  'feedback.error.message':
    'There was an error while sending your feedback. Please try again.',
  'feedback.error.button': 'Try again',
  'feedback.progress.label':
    'Question {currentQuestionIndex} of {numberOfQuestions}',
  'feedback.question.easeOfUseSelect':
    'How easy or difficult are Shared Tabs to use?',
  'feedback.question.magicWandText':
    'Which improvements would you make to Shared Tabs?',
  'feedback.question.npsSelect':
    'From 1 to 10, how likely are you to recommend Shared Tabs to a friend or family member?',
  'feedback.question.whyNotMonzoText':
    'What is stopping you from becoming a full Monzo user?',
  'feedback.select.easeOfUseSelect.option1': 'Extremely easy',
  'feedback.select.easeOfUseSelect.option2': 'Easy',
  'feedback.select.easeOfUseSelect.option3': 'Neither easy or difficult',
  'feedback.select.easeOfUseSelect.option4': 'Difficult',
  'feedback.select.easeOfUseSelect.option5': 'Extremely difficult',
  'feedback.select.npsSelect.option1': '1: Extremely unlikely',
  'feedback.select.npsSelect.option2': '2',
  'feedback.select.npsSelect.option3': '3',
  'feedback.select.npsSelect.option4': '4',
  'feedback.select.npsSelect.option5': '5',
  'feedback.select.npsSelect.option6': '6',
  'feedback.select.npsSelect.option7': '7',
  'feedback.select.npsSelect.option8': '8',
  'feedback.select.npsSelect.option9': '9',
  'feedback.select.npsSelect.option10': '10: Extremely likely',
  'feedback.success.title': 'Thanks for your feedback',
  'feedback.success.text':
    'We read each response and your input will help us shape the future of Shared Tabs.',
  'feedback.success.button': 'Back to {tabName}',
  'settings.page.title': 'Notification settings',
  'settings.page.description':
    'Shared Tab notifications are only sent by email. Settings will apply to all of your Shared Tabs.',
  'settings.switch.activity.label': 'Tab activity',
  'settings.switch.activity.sublabel': 'Tap to turn {activityConsent}',
  'settings.switch.activity.sublabel.on': 'on',
  'settings.switch.activity.sublabel.off': 'off',
  'settings.button': 'Save changes',
  'settings.success.title': "You've updated your notification settings",
  'settings.success.text.consented':
    'You will now receive activity email for all your Shared Tabs.',
  'settings.success.text.not-consented':
    "You won't receive any more emails about your Shared Tabs.",
  'settings.success.button': 'Back to {tabName}',
  'settings.error.title': 'Something went wrong',
  'settings.error.text':
    'There was an error loading or saving your settings. Please try again.',
  'settings.error.button': 'Try again',
  'signup.error.title': 'Woops!',
  'signup.error.text':
    'There was an error signing you up. Please try again or ask the tab creator for a new invite link.',
  'signup.card.title': 'One last thing, enter your name',
  'signup.card.form.label': 'Your name',
  'signup.card.form.placeholder': 'First and last name',
  'signup.card.form.error':
    'Please enter a name with no numbers and at least one character.',
  'signup.card.button': 'Join {tabName}',
  'leave.dialog.title': 'Are you sure you want to leave this tab?',
  'leave.dialog.subtitle': 'You can only rejoin with a valid invite link.',
  'leave.dialog.button.back': 'Go back',
  'leave.dialog.button.leave': 'Leave tab',
  'leave.dialog.error': 'Something went wrong, please try again.',
  'details.people.title.singular': '{count} person',
  'details.people.title.plural': '{count} people',
  'details.add-bill.title': 'Add custom expense',
  'details.settle-up.title': 'Settle up',
  'details.settle-up.subtitle': 'Mark tabs as paid',
  'details.send-reminder.title': 'Send a reminder',
  'details.send-reminder.subtitle': 'Nudge your friends to pay',
  'details.feedback.title': 'Give us feedback',
  'details.feedback.subtitle': 'Help shape the future of Shared Tabs',
  'details.settings.title': 'Notification settings',
  'details.logout.title': 'Log out',
  'details.logout.subtitle': 'You can log back in at any time',
  'details.leave-tab.title': 'Leave tab',
  'details.leave-tab.subtitle.enabled': 'Permanently leave {tabName}',
  'details.leave-tab.subtitle.disabled':
    "You can't leave if you owe or are owed",
  'woops.page.title': 'Woops!',
  'woops.page.full_monzo_user.text':
    'The email address you used is registered to a Monzo account.\n Open this Shared Tab in the Monzo App, or click the invite link again to sign up with a different email address.',
} as const

//Get the keys of the object as a type
export type enGBKeys = keyof typeof enGB
